<template>
  <div>
    <phlix-navbar></phlix-navbar>
    <glide :images="popular" :breakpoints="[[0, 1], [1024,2], [1440,3]]" :navigation="false"></glide>
    <div
      class="columns is-multiline"
      style="margin-top:2rem; margin-left: 0.75rem; margin-right:0.75rem; margin-bottom: 3rem"
    >
      <!-- Recommendations -->
      <div v-if="signedin" class="column is-12">
        <p class="title is-size-4-desktop is-size-5-mobile">
          <router-link to="/recommendations">
            <span style="vertical-align: middle">Recommendations</span>
            <font-awesome-icon
              style="vertical-align: middle; margin-left: 0.375rem"
              icon="chevron-right"
            ></font-awesome-icon>
          </router-link>
        </p>
        <p class="subtitle is-6">Based on your favorites</p>
      </div>
      <div v-if="signedin" class="column is-6">
        <movieandtvsidescroll :items="movierecs" displaytext="movies" to="movies"></movieandtvsidescroll>
      </div>
      <div v-if="signedin" class="column is-6">
        <movieandtvsidescroll :items="tvrecs" displaytext="tv" to="tv"></movieandtvsidescroll>
      </div>
      <!-- End Recommendations -->

      <!-- Now Playing -->
      <div class="column is-12" style="margin-top: 2rem; margin-bottom: 1.5rem">
        <p class="title is-size-4-desktop is-size-5-mobile">Now Playing</p>
        <p class="subtitle is-6">In theaters worldwide</p>
      </div>
      <div class="column is-12 is-marginless is-paddingless">
        <glide
          :images="nowplaying"
          style="margin-left: -0.75rem; margin-right:-0.75rem;"
          :breakpoints="[[0, 1], [1024,3], [1440,5]]"
        ></glide>
      </div>
      <!-- End Now Playing -->

      <!-- Top Rated -->
      <div class="column is-12" style="margin-top:3rem">
        <p class="title is-size-4-desktop is-size-5-mobile">Top Rated</p>
        <p class="subtitle is-6">Movies and TV shows</p>
      </div>
      <div class="column is-6">
        <movieandtvsidescroll :items="topratedmovies" displaytext="movies" to="movies"></movieandtvsidescroll>
      </div>
      <div class="column is-6">
        <movieandtvsidescroll :items="topratedtv" displaytext="tv" to="tv"></movieandtvsidescroll>
      </div>
      <!-- End Top Rated -->

      <!-- Currently Airing -->
      <div class="column is-12" style="margin-top: 2rem; margin-bottom: 1.5rem">
        <p class="title is-size-4-desktop is-size-5-mobile">Currently Airing</p>
        <p class="subtitle is-6">TV Shows</p>
      </div>
      <div class="column is-12 is-marginless is-paddingless">
        <glide
          :images="tvontheair"
          style="margin-left: -0.75rem; margin-right:-0.75rem;"
          :breakpoints="[[0, 1], [1024,3], [1440,5]]"
        ></glide>
      </div>
      <!-- End currently airing -->

      <!-- Upcoming movies -->
      <div class="column is-12" style="margin-top: 2rem; margin-bottom: 1.5rem">
        <p class="title is-size-4-desktop is-size-5-mobile">Upcoming Movies</p>
        <p class="subtitle is-6">In Theaters</p>
      </div>
      <div class="column is-12 is-marginless is-paddingless">
        <glide
          :images="upcomingmovies"
          style="margin-left: -0.75rem; margin-right:-0.75rem;"
          :breakpoints="[[0, 1], [1024,3], [1440,5]]"
        ></glide>
      </div>
      <!-- End upcoming movies -->
    </div>
    <div class="footer-padding">
      <phlix-footer></phlix-footer>
    </div>
    <bottomnavbar item="home"></bottomnavbar>
  </div>
</template>

<script>
import Bottomnavbar from "@/components/Bottomnavbar";
import Navbar from "@/components/Navbar.vue";
import Glide from "@/components/Glide.vue";
import TMDb from "@/utils/TMDb.js";
import Scrollmenu from "@/components/Sidescroll/Scrollmenu.vue";
import Imagebutton from "@/components/Imagebutton.vue";
import Movieandtvsidescroll from "@/components/Movieandtvsidescroll.vue";
import Footer from "@/components/Footer.vue";

const tmdb = new TMDb();

export default {
  name: "home",
  data() {
    return {
      popular: [],
      movierecs: [],
      tvrecs: [],
      nowplaying: [],
      topratedmovies: [],
      topratedtv: [],
      tvontheair: [],
      upcomingmovies: [],
      signedin: tmdb.isSignedIn(),
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      this.getTitledBackdrops(tmdb.getPopular, this.popular);

      if (this.signedin) {
        tmdb
          .getMovieRecommendations()
          .then((resp) => (this.movierecs = resp.data.results));
        tmdb
          .getTvRecommendations()
          .then((resp) => (this.tvrecs = resp.data.results));
      }

      this.getTitledBackdrops(
        tmdb.getNowPlayingMovies,
        this.nowplaying,
        "movie"
      );

      tmdb
        .getTopRatedMovies()
        .then((resp) => (this.topratedmovies = resp.data.results));

      tmdb
        .getTopRatedTv()
        .then((resp) => (this.topratedtv = resp.data.results));

      this.getTitledBackdrops(tmdb.getTvOnTheAir, this.tvontheair, "tv");

      this.getTitledBackdrops(
        tmdb.getUpcomingMovies,
        this.upcomingmovies,
        "movie"
      );
    },

    getTitledBackdrops(func, out, media_type = undefined, page = 1) {
      //Getting just the titled backdrops for any api call is not as simple as you may think.
      //This was not easy to make
      let vm = this;
      func(page).then(async function (resp) {
        let filtered = resp.data.results;
        filtered.forEach((item) => {
          if (item.media_type == undefined) {
            item["media_type"] = media_type;
          }
        });
        filtered.filter((item) => {
          item.media_type !== "person";
        });
        let filterBackdrops = async function (filtered) {
          for (let item of filtered) {
            if (out.length == 20) {
              return;
            }
            let resp = [];
            if (item.media_type == "movie") {
              resp = await tmdb.getMovieBackdropWithText(item.id);
            } else {
              resp = await tmdb.getTvBackdropWithText(item.id);
            }
            let drops = resp.data.backdrops;
            drops.length > 0
              ? (item["backdrop_path"] = drops[0].file_path)
              : {};

            out.push(item);
          }
        };
        filterBackdrops(filtered).then(() => {
          if (out.length < 20) {
            vm.getTitledBackdrops(func, out, media_type, page + 1);
          }
        });
      });
    },
  },
  components: {
    "phlix-navbar": Navbar,
    Bottomnavbar,
    Glide,
    Scrollmenu,
    Imagebutton,
    Movieandtvsidescroll,
    "phlix-footer": Footer,
  },
};
</script>

<style>
.footer-padding {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 4.5rem;
}
</style>