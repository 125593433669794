<template>
  <div :id="randomId">
    <carousel
      class="pushbuttonsup"
      :autoplay="true"
      :autoplayTimeout="7000"
      :speed="speed"
      :minSwipeDistance="50"
      :scrollPerPage="false"
      easing="ease-in-out"
      :perPageCustom="breakpoints"
      :paginationEnabled="true"
      paginationPosition="bottom-overlay"
      :paginationSize="8"
      :paginationPadding="4"
      :loop="true"
    >
      <slide v-for="(item, index) in filteredImages" :key="index">
        <div class="desktop-margin">
          <router-link :to="{ name: redirectName(item.media_type), params: {id: item.id } }">
            <img :src="'https://image.tmdb.org/t/p/w1280//' + item.backdrop_path" />
          </router-link>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import TMDb from "@/utils/TMDb.js";

const tmdb = new TMDb();

export default {
  name: "glide",
  props: ["images", "breakpoints"],
  methods: {
    changeSpeed() {
      window.alert("speed");
      this.speed = 50;
    },
    redirectName(type) {
      if (type == "movie") {
        return "movies";
      } else if (type == "tv") {
        return "tv";
      } else if (type == "person") {
        return "person";
      } else {
        return "undefined";
      }
    },
  },
  data() {
    return {
      speed: 1500
    };
  },
  mounted() {
    let vm = this
    document.getElementById(this.randomId).addEventListener("touchstart", function() {
      //alert('touch')
      vm.speed = 50;
    });
    document.getElementById(this.randomId).addEventListener("touchend", function() {
      //alert('touch')
      vm.speed = 1500;
    });
  },
  computed: {
    randomId() {
      var array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      var id = "glide" + array.find(Number);
      return id;
    },

    filteredImages() {
      return this.images.filter(item => item.backdrop_path != null)
    }
  },
  components: {
    Carousel,
    Slide
  }
};
</script>

<style>
.pushbuttonsup button {
  position: relative;
  bottom: 1rem;
  outline: none;
  box-shadow: none;
}
.pushbuttonsup button:active:focus:hover::after {
  outline: none;
  box-shadow: none;
}

@media screen and (min-width: 600px) {
  .desktop-margin {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .desktop-margin img {
    border-radius: 5px;
  }
}
</style>

